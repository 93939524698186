<template>
  <div class="form-express">
    <div id="efec-minimizar">
      <a @click="show = !show">
        <img src="img/icon-close.png" class="float-end" alt="icono close" />
      </a>
      <div class="encabezado-express">
        <h3 class="titulo-express">SIMULADOR RÁPIDO</h3>
      </div>
      <!-- Transicion minimizar -->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <div v-if="show" class="contenido-form">
          <form method="post" @submit.prevent="cotizarRenta">
            <p class="txt-formexpres">Tu renta liquida ($)</p>

            <CurrencyInput
              v-model="renta"
              class="input-express text-center"
              :options="{
                locale: 'es-CL',
                currency: 'CLP',
                currencyDisplay: 'hidden',
                precision: 0,
                hideGroupingSeparatorOnFocus: false,
                valueRange: {
                  min: 0,
                  max: 100000000,
                },
              }"
            />

            <br />
            <button :disabled="renta == null" class="btn btn-calcular w-50">
              CALCULAR
            </button>
          </form>

          <div class="resultado-express p-3">
            <div class="row linea-inferior p-1">
              <div class="col-lg-8 text-express">Dividendo máx. ($)</div>
              <div class="col-lg-4 text-express-r">
                <span v-if="status != 200"> 0 </span
                ><span v-if="status == 200">
                  {{ formatPrice(valor.dividend * ufhoy) }}</span
                >
              </div>
            </div>
            <div class="row p-1">
              <div class="col-lg-8 text-express">Crédito max (UF)</div>
              <div class="col-lg-4 text-express-r">
                <span v-if="status != 200"> 0 </span>
                <span v-if="status == 200">{{
                  formatPrice(valor.credit)
                }}</span>
              </div>
            </div>
          </div>

          <div class="cotizar d-none">
            <span class="text-cotizar">para continuar simulación</span> <br />
            <router-link to="/home-hogar" class="click linea-inferior-celeste">
              Click Aquí
            </router-link>
          </div>
        </div>
      </transition>
      <!-- Transicion minimizar -->
    </div>
  </div>
</template>

<script>
import CurrencyInput from '../components/inputs/InputRenta.vue';
import SimulationService from '../service/rest/Simulation.service';
import UFService from '../service/rest/UF.service';

export default {
  components: {
    CurrencyInput,
  },

  data() {
    return {
      show: true,
      form: {
        income: 0,
      },
      renta: 0,
      valor: [],
      status: [],
      error: 0,
      ufhoy: [],
      errormensaje: [],
      alertInput: false,
    };
  },
  created() {
    this.getUF();
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    // funcion para consultar api rest y generar la consulta para cotizador express
    async cotizarRenta() {
      this.alertInput = true;

      const response = await SimulationService.simulationExpress(this.renta);
      if (response.status === 200) {
        this.valor = response.data;
      }

      this.status = response.status;
    },

    async getUF() {
      const ufResponse = await UFService.getUFDay();
      if (ufResponse.status === 200) {
        this.ufhoy = ufResponse.data.value;
      }
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
