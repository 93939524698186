<template>
  <div class="Home">
    <div class="container text-left menu_ajuste">
      <!-- ROW -->
      <div class="row p-4">
        <h2 class="tt-litas p-4">Licitaciones</h2>

        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Adjudicación Licitación</h3>
            <p class="tx_lic">
              Adjudicación licitación pública para para póliza colectiva de
              seguro de desgravamen por fallecimiento, incluida y excluida la
              cobertura adicional de invalidez total y permanente dos tercios,
              asociadas a la cartera de créditos hipotecarios que evoluciona
              administradora de mutuos hipotecarios s.a., otorgue y administre
              en su calidad de tal.
              <br />
              <strong>26 de noviembre del 2024.</strong>
            </p>
            <a
              href="files/licitaciones/Adjudicacion-Licitacion-Desgravamen-DFyWeb2024.pdf"
            >
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>

        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Llamado a Licitación</h3>
            <p class="tx_lic">
              Llamado a licitación pública de seguros de desgravamen y
              desgravamen con invalidez y permanente 2/3 asociados a créditos
              hipotecarios de evoluciona administradora de mutuos hipotecarios
              s.a.
              <br />
              <strong>02 de octubre del 2024.</strong>
            </p>
            <a href="files/licitaciones/licitationDesg2024.pdf" target="_blank">
              <img
                src="img/licita.png"
                title="Descargar Llamado a Licitación de Hipotecaria Evoluciona"
                alt="Descargar Llamado a Licitación de Hipotecaria Evoluciona"
            /></a>
          </div>
          <!-- card-->
        </div>

        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Adjudicación Licitación</h3>
            <p class="tx_lic">
              Adjudicación licitación pública para para póliza colectiva de
              seguro de desgravamen por fallecimiento, incluida y excluida la
              cobertura adicional de invalidez total y permanente dos tercios,
              asociadas a la cartera de créditos hipotecarios que evoluciona
              administradora de mutuos hipotecarios s.a., otorgue y administre
              en su calidad de tal.
              <br />
              <strong>22 de noviembre del 2023.</strong>
            </p>
            <a
              href="files/licitaciones/AdjudicaciónLicitaciónDesgravamenDFyWeb2023.pdf"
            >
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Adjudicación Licitación</h3>
            <p class="tx_lic">
              Adjudicación licitación pública para póliza colectiva de seguro de
              Incendio y Cobertura Complementaria, incluido sismo, asociadas a
              la cartera de créditos hipotecarios que Evoluciona Administradora
              de Mutuos Hipotecarios S.A., administre y otorgue en su calidad de
              tal.22 de noviembre del 2023.
              <br />
              <strong>22 de noviembre del 2023.</strong>
            </p>
            <a
              href="files/licitaciones/PublicaciónAdjudicaciónLicitaciónIncendioySismo2023.pdf"
            >
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Llamado a Licitación</h3>
            <p class="tx_lic">
              Llamado a licitación pública de seguros de desgravamen y
              desgravamen con invalidez y permanente 2/3 asociados a créditos
              hipotecarios de evoluciona administradora de mutuos hipotecarios
              s.a.
              <br />
              <strong>02 de octubre del 2023.</strong>
            </p>
            <a href="files/licitaciones/licitationDesg2023.pdf">
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Llamado a Licitación</h3>
            <p class="tx_lic">
              Llamado a licitación pública de seguros de incendio y adicionales
              incluyendo sismo asociados a créditos hipotecarios de evoluciona
              administradora de mutuos hipotecarios s.a.
              <br />
              <strong>02 de octubre del 2023.</strong>
            </p>
            <a href="files/licitaciones/licitationIncendioSismo2023.pdf">
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Adjudicación licitación</h3>
            <p class="tx_lic">
              Adjudicación licitación pública para para póliza colectiva de
              seguro de desgravamen por fallecimiento, incluida y excluida la
              cobertura adicional de invalidez total y permanente dos tercios,
              asociadas a la cartera de créditos hipotecarios que evoluciona
              administradora de mutuos hipotecarios s.a., otorgue y administre
              en su calidad de tal.
              <br />
              <strong>10 de noviembre del 2022.</strong>
            </p>
            <a
              href="files/licitaciones/AdjudicaciónLicitaciónDesgravamenDFyWeb.pdf"
            >
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Adjudicación licitación</h3>
            <p class="tx_lic">
              Adjudicación licitación pública para póliza colectiva de seguro de
              Incendio y Cobertura Complementaria, incluido sismo, asociadas a
              la cartera de créditos hipotecarios que Evoluciona Administradora
              de Mutuos Hipotecarios S.A., administre y otorgue en su calidad de
              tal.
              <br />
              <strong>9 de noviembre del 2022.</strong>
            </p>
            <a href="files/licitaciones/PublicaciónDF2022.pdf">
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Llamado a licitación</h3>
            <p class="tx_lic">
              Llamado a licitación publica de seguros de desgravamen y
              desgravamen con invalidez y permanente 2/3 asociados a créditos
              hipotecarios de evoluciona administradora de mutuos hipotecarios
              s.a.
              <br />
              <strong>30 de octubre del 2022.</strong>
            </p>
            <a
              href="files/licitaciones/1022_licitacion_seguros_WEB_10-2020.pdf"
            >
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->

        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Llamado a licitación</h3>
            <p class="tx_lic">
              Llamado a licitación publica de seguros de incendio y adicionales
              incluyendo sismo asociados a créditos hipotecarios de evoluciona
              administradora de mutuos hipotecarios s.a.
              <br />
              <strong>15 de septiembre del 2022.</strong>
            </p>
            <a href="files/licitaciones/licitacion-seguros-sep.pdf">
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
        <!-- BOX -->

        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Adjudicación licitación</h3>
            <p class="tx_lic">
              Adjudicación licitación pública para póliza colectiva de seguro de
              Incendio y Cobertura Complementaria, incluido sismo, asociadas a
              la cartera de créditos hipotecarios que Evoluciona Administradora
              de Mutuos Hipotecarios S.A., administre y otorgue en su calidad de
              tal.
              <br />
              <strong>27 de octubre del 2021.</strong>
            </p>
            <a
              href="files/licitaciones/AdjudicacionLicitacionIncendioySismoWEB.pdf"
            >
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Llamado a licitación</h3>
            <p class="tx_lic">
              llamado a licitación pública de seguros de desgravamen y
              desgravamen con invalidez total y permanente 2/3 asociados a
              créditos hipotecarios de evoluciona administradora de mutuos
              hipotecarios s.a.
              <br />
              <strong>1 de octubre del 2021.</strong>
            </p>
            <a href="files/licitaciones/290921LLAMADOLICITACIONPUBLICAWEB.pdf">
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Adjudicación licitación</h3>
            <p class="tx_lic">
              Adjudicación licitación pública para para póliza colectiva de
              seguro de desgravamen por fallecimiento, incluida y excluida la
              cobertura adicional de invalidez total y permanente dos tercios,
              asociadas a la cartera de créditos hipotecarios que evoluciona
              administradora de mutuos hipotecarios s.a., otorgue y administre
              en su calidad de tal.
              <br />
              <strong>25 de noviembre del 2021.</strong>
            </p>
            <a
              href="files/licitaciones/AdjudicacionLicitacionDesgravamenDFWEB.pdf"
            >
              <img src="img/licita.png" alt="descargar"
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Llamado a licitación</h3>
            <p class="tx_lic">
              Adjudicación licitación pública para póliza colectiva de seguro de
              incendio y cobertura complementaria, incluido sismo, asociadas a
              la cartera de créditos hipotecarios que evoluciona administradora
              de mutuos hipotecarios s.a., administra y otorgue en su calidad de
              tal.
              <br />
              <strong>1 de Septiembre del 2021.</strong>
            </p>
            <a
              href="files/licitaciones/EvolucionaLicitacion1septiembre01092021.pdf"
            >
              <img src="img/licita.png" alt=""
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->

        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Llamado a licitación</h3>
            <p class="tx_lic">
              Adjudicación licitación pública para póliza colectiva de seguro de
              incendio y cobertura complementaria, incluido sismo, asociadas a
              la cartera de créditos que evoluciona administradora de mutuos
              hipotecarios S.A., Otorgue en su calidad de tal.
              <br />
              <strong>23 de Octubre del 2020.</strong>
            </p>
            <a
              href="files/licitaciones/AdjLicitacionIncendioySismo23102020.pdf"
            >
              <img src="img/licita.png" alt=""
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->

        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Llamado a licitación</h3>
            <p class="tx_lic mb-2">
              Llamado a licitación pública de seguros de incendios y adicionales
              incluyendo sismo asociados a créditos hipotecarios de evoluciona
              administradora de mutuos hipotecarios S.A
              <br />
              <strong>31 de Agosto del 2020.</strong>
            </p>
            <a href="files/licitaciones/EvolucionaPublicacionDF31082020.pdf">
              <img src="img/licita.png" alt=""
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->

        <!-- BOX -->

        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Póliza incendio y sismo</h3>
            <a href="files/licitaciones/POLIZA50-200-22100338.pdf">
              <img src="img/licita.png" alt=""
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->

        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Póliza desgravamen incluido itp 2/3</h3>
            <a
              href="files/licitaciones/22379CondicionesParticularesEvoluciona.pdf"
            >
              <img src="img/licita.png" alt=""
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
        <div class="col-lg-4">
          <!-- card-->
          <div class="card-lic text-left">
            <h3 class="tt_lic">Póliza desgravamen excluido itp 2/3</h3>
            <a href="files/licitaciones/Poliza1012036signed.pdf">
              <img src="img/licita.png" alt=""
            /></a>
          </div>
          <!-- card-->
        </div>
        <!-- BOX -->
      </div>

      <!-- ROW -->
    </div>
    <!-- Cierre Contenedor  -->
  </div>
</template>

<script>
import CommonService from '../service/CommonService';

export default {
  name: 'Licitaciones',
  created() {
    CommonService.scrollToTop();
  },
};
</script>
